<template>
	<div class="container" v-if="agent">

		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'users-index'}">Users</router-link>
				</li>
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'users-edit-user_id', params: {user_id: this.agent.Id}}"
								 v-text="agent.Username">
					</router-link>
				</li>
			</ol>
		</nav>

		<agent-profile-form :disabled="!canWrite('users')" :show-role="true" :show-access="true" v-model="agent" @submit="save"/>

		<div class="alert alert-danger p-3 text-center mt-5" v-if="canWrite('users')">
			<div class="my-2">DELETE {{agent.Username}}</div>
			<div class="my-5">
				<button class="btn btn-danger" @click.prevent="deleteUser">DELETE</button>
			</div>
		</div>

	</div>
</template>

<script>
	import api from '@/api'
	import AgentProfileForm from "@/components/agents/agent-profile-form";
	import messageBox from '@/components/modals/alert'
	import confirmBox from '@/components/modals/confirm'

	export default {
		data() {
			return {
				agent: null
			}
		},
		mounted() {
			this.loadUser(this.$route.params.user_id)
		},
		methods: {
			async loadUser(user_id) {
				this.agent = await api.Agents.get(user_id)
			},
			async save() {
				this.agent = await api.Agents.update(this.agent.Id, this.agent)

				if (this.agent.Id === this.$store.getters['User/getUser'].Id) {
					this.$store.commit("User/userData", this.agent)
				}

				messageBox({
					message: `Profile updated!`
				})
			},
			async deleteUser() {
				confirmBox({
					message: `Want to delete user ${this.agent.Username}?`
				})
						.then(
								() => api.Agents.delete(this.agent.Id).then(
										messageBox({
											message: `User deleted!`
										}).then(
												() => this.$router.push({name: 'users-index'})
										)
								)
						)
			}
		},
		components: {
			AgentProfileForm
		}
	}
</script>
